import React from 'react';
import Woman from "../components/UI/Woman/Woman";
import alexandra from '../img/women/alexandra.JPG';
import alexandra_1 from '../img/women/alexandra_1.JPG';
import alexandra_2 from '../img/women/alexandra_2.JPEG';
import alexandra_3 from '../img/women/alexandra_3.JPG';
import alexandra_4 from '../img/women/alexandra_4.JPEG';
import anastasia from '../img/women/anastasia.png';
import anastasia_1 from '../img/women/anastasia_1.jpeg';
import anastasia_2 from '../img/women/anastasia_2.jpeg';
import anastasia_3 from '../img/women/anastasia_3.jpeg';
import anastasia_4 from '../img/women/anastasia_4.jpg';
import anastasia_second from '../img/women/anastasia-2.jpg';
import anastasia_second_1 from '../img/women/anastasia-2_1.jpg';
import anastasia_second_2 from '../img/women/anastasia-2_2.JPEG';
import anastasia_second_3 from '../img/women/anastasia-2_3.jpg';
import anastasia_second_4 from '../img/women/anastasia-2_4.jpg';
import antonida from '../img/women/antonida.jpg';
import antonida_1 from '../img/women/antonida_1.jpg';
import antonida_2 from '../img/women/antonida_2.JPG';
import antonida_3 from '../img/women/antonida_3.jpg';
import antonida_4 from '../img/women/antonida_4.jpg';
import aigul from '../img/women/aigul.JPG';
import aigul_1 from '../img/women/aigul_1.JPG';
import aigul_2 from '../img/women/aigul_2.JPG';
import aigul_3 from '../img/women/aigul_3.JPG';
import aigul_4 from '../img/women/aigul_4.JPG';
import daria from '../img/women/daria.JPG';
import daria_1 from '../img/women/daria_1.JPG';
import daria_2 from '../img/women/daria_2.JPG';
import daria_3 from '../img/women/daria_3.JPG';
import daria_4 from '../img/women/daria_4.JPG';
import dayria from '../img/women/dayria.JPEG';
import dayria_1 from '../img/women/dayria_1.jpg';
import dayria_2 from '../img/women/dayria_2.jpg';
import dayria_3 from '../img/women/dayria_3.jpg';
import dayria_4 from '../img/women/dayria_4.JPG';
import diana from '../img/women/diana.JPG';
import diana_1 from '../img/women/diana_1.JPG';
import diana_2 from '../img/women/diana_2.JPG';
import diana_3 from '../img/women/diana_3.JPG';
import diana_4 from '../img/women/diana_4.JPG';
import elena from '../img/women/elena.jpg';
import elena_1 from '../img/women/elena_1.jpg';
import elena_2 from '../img/women/elena_2.JPG';
import elena_3 from '../img/women/elena_3.JPG';
import elena_4 from '../img/women/elena_4.JPG';
import elena_second from '../img/women/elena-2.jpg';
import elena_second_1 from '../img/women/elena-2_1.jpg';
import elena_second_2 from '../img/women/elena-2_2.jpg';
import elena_second_3 from '../img/women/elena-2_3.jpg';
import elena_second_4 from '../img/women/elena-2_4.JPG';
import elena_third from '../img/women/elena-3.JPG';
import elena_third_1 from '../img/women/elena-3_1.jpeg';
import elena_third_2 from '../img/women/elena-3_2.PNG';
import elena_third_3 from '../img/women/elena-3_3.JPG';
import elena_third_4 from '../img/women/elena-3_4.jpg';
import elena_fourth_1 from '../img/women/elena-4_1.JPG';
import elena_fourth_2 from '../img/women/elena-4_2.JPG';
import elena_fourth_3 from '../img/women/elena-4_3.JPG';
import elena_fourth_4 from '../img/women/elena-4_4.JPG';
import elena_fourth_5 from '../img/women/elena-4_5.JPG';
import ludmila from '../img/women/ludmila.JPG';
import inna from '../img/women/inna.JPEG';
import inna_1 from '../img/women/inna_1.JPEG';
import inna_2 from '../img/women/inna_2.JPEG';
import inna_3 from '../img/women/inna_3.JPEG';
import inna_4 from '../img/women/inna_4.JPEG';
import irina from '../img/women/irina.JPG';
import irina_1 from '../img/women/irina_1.JPG';
import irina_2 from '../img/women/irina_2.JPG';
import irina_3 from '../img/women/irina_3.JPG';
import kristina from '../img/women/kristina.JPG';
import kristina_1 from '../img/women/kristina_1.jpg';
import kristina_2 from '../img/women/kristina_2.jpg';
import kristina_3 from '../img/women/kristina_3.JPG';
import kristina_4 from '../img/women/kristina_4.JPEG';
import kristina_second from '../img/women/kristina-2.jpg';
import kristina_second_1 from '../img/women/kristina-2_1.jpg';
import kristina_second_2 from '../img/women/kristina-2_2.png';
import kristina_second_3 from '../img/women/kristina-2_3.JPG';
import ksenia from '../img/women/ksenia.JPG';
import ksenia_1 from '../img/women/ksenia_1.JPG';
import ksenia_2 from '../img/women/ksenia_2.JPG';
import ksenia_3 from '../img/women/ksenia_3.JPG';
import ksenia_4 from '../img/women/ksenia_4.JPG';
import ksenia_second from '../img/women/ksenia-2.JPG';
import ksenia_second_1 from '../img/women/ksenia-2_1.JPG';
import ksenia_second_2 from '../img/women/ksenia-2_2.JPG';
import ksenia_second_3 from '../img/women/ksenia-2_3.JPG';
import ksenia_second_4 from '../img/women/ksenia-2_4.JPG';
import kate from '../img/women/kate.JPG';
import kate_1 from '../img/women/kate_1.JPG';
import kate_2 from '../img/women/kate_2.JPG';
import kate_3 from '../img/women/kate_3.JPG';
import kate_4 from '../img/women/kate_4.JPG';
import ludmila_1 from '../img/women/ludmila_1.JPG';
import ludmila_2 from '../img/women/ludmila_2.JPG';
import ludmila_3 from '../img/women/ludmila_3.jpg';
import ludmila_4 from '../img/women/ludmila_4.JPG';
import maria from '../img/women/maria.JPG';
import maria_1 from '../img/women/maria_1.JPG';
import maria_2 from '../img/women/maria_2.JPG';
import maria_3 from '../img/women/maria_3.JPG';
import maria_4 from '../img/women/maria_4.JPG';
import maria_5 from '../img/women/maria_5.jpg';
import maria_6 from '../img/women/maria_6.jpg';
import mila from '../img/women/mila.JPG';
import mila_1 from '../img/women/mila_1.jpg';
import mila_2 from '../img/women/mila_2.JPG';
import mila_3 from '../img/women/mila_3.JPG';
import natalya from '../img/women/natalya.JPG';
import natalya_1 from '../img/women/natalya_1.JPG';
import natalya_2 from '../img/women/natalya_2.JPG';
import natalya_3 from '../img/women/natalya_3.JPG';
import natalya_4 from '../img/women/natalya_4.JPG';
import natalya_second_1 from '../img/women/natalya-2_1.JPG';
import natalya_second_2 from '../img/women/natalya-2_2.JPG';
import natalya_second_3 from '../img/women/natalya-2_3.JPG';
import natalya_second_4 from '../img/women/natalya-2_4.JPG';
import olesya from '../img/women/olesya.JPG';
import olesya_1 from '../img/women/olesya_1.JPG';
import olesya_2 from '../img/women/olesya_2.JPG';
import olesya_3 from '../img/women/olesya_3.JPG';
import olga_second_2 from '../img/women/olga-2_2.JPG';
import olga_second_3 from '../img/women/olga-2_3.JPG';
import olga_second_4 from '../img/women/olga-2_4.JPG';
import olga_second_5 from '../img/women/olga-2_5.jpg';
import olga_second_6 from '../img/women/olga-2_6.jpeg';
import olga_second_7 from '../img/women/olga-2_7.JPG';
import olga_second_8 from '../img/women/olga-2_8.JPG';
import polina from '../img/women/polina.jpg';
import polina_1 from '../img/women/polina_1.JPG';
import polina_2 from '../img/women/polina_2.jpg';
import polina_3 from '../img/women/polina_3.jpg';
import rumia from '../img/women/rumia.JPG';
import rumia_1 from '../img/women/rumia_1.JPEG';
import rumia_2 from '../img/women/rumia_2.JPG';
import rumia_3 from '../img/women/rumia_3.JPG';
import rumia_4 from '../img/women/rumia_4.JPEG';
import svetlana from '../img/women/svetlana.JPEG';
import svetlana_1 from '../img/women/svetlana_1.jpg';
import svetlana_2 from '../img/women/svetlana_2.JPEG';
import svetlana_3 from '../img/women/svetlana_3.PNG';
import svetlana_4 from '../img/women/svetlana_4.JPEG';
import svetlana_second from '../img/women/svetlana-2.jpg';
import svetlana_second_1 from '../img/women/svetlana-2_1.JPEG';
import svetlana_second_2 from '../img/women/svetlana-2_2.jpeg';
import svetlana_second_3 from '../img/women/svetlana-2_3.jpg';
import svetlana_second_4 from '../img/women/svetlana-2_4.jpg';
import valeria from '../img/women/valeria.PNG';
import valeria_1 from '../img/women/valeria_1.jpeg';
import valeria_2 from '../img/women/valeria_2.jpeg';
import valeria_3 from '../img/women/valeria_3.jpeg';
import valeria_4 from '../img/women/valeria_4.JPG';
import larisa_1 from '../img/women/larisa_1.JPG';
import larisa_2 from '../img/women/larisa_2.JPG';
import larisa_3 from '../img/women/larisa_3.JPG';
import larisa_4 from '../img/women/larisa_4.JPG';

import Request from "../components/Request/Request";
import '../style/Womans.css';
import backWhite from '../img/white-left-back.svg';
import { useTranslation } from "react-i18next";

const Womans = () => {
	const { t } = useTranslation();

	const womansCol_1 = [
		{
			name: 'Elena',
			photos: [elena_4, elena, elena_1, elena_2, elena_3],
			className: 'my_woman__1 my_woman',
			birthdate: '1988-11-22',
			height: "1 m 64",
			status: t("women.single"),
			children: 2,
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (A2)`,
			profession: t("women.professions.brandDesigner")

		},
		{
			name: 'Natalya',
			photos: [natalya, natalya_1, natalya_2, natalya_3, natalya_4],
			className: 'my_woman__1 my_woman',
			birthdate: '1975-08-18',
			height: "1 m 64",
			status: t("women.divorced"),
			children: 1,
			smoking: t("women.yes"),
			language: `${t("women.languages.english")} (A1), ${t("women.languages.german")} (A1)`,
			profession: t("women.professions.lawyer")

		},
		{
			name: 'Olga',
			photos: [olga_second_2, olga_second_3, olga_second_4, olga_second_5, olga_second_6, olga_second_7, olga_second_8],
			className: 'my_woman__4 my_woman',
			birthdate: '1980-03-17',
			height: "1 m 62",
			status: t("women.single"),
			children: t("women.no"),
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (A2)`,
			profession: t("women.professions.lawyer")

		},
		{
			name: 'Ludmila',
			photos: [ludmila, ludmila_1, ludmila_2, ludmila_3, ludmila_4],
			className: 'my_woman__1 my_woman',
			birthdate: '1973-08-13',
			height: "1 m 64",
			status: t("women.single"),
			children: 1,
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (A1)`,
			profession: t("women.professions.artist")

		},
		{
			name: 'Maria',
			photos: [maria, maria_1, maria_2, maria_3, maria_4, maria_5, maria_6],
			className: 'my_woman__5 my_woman',
			birthdate: '1986-05-17',
			height: "1 m 70",
			status: t("women.divorced"),
			children: 1,
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (B2)`,
			profession: t("women.professions.artist")

		},
		{
			name: 'Ksenia',
			photos: [ksenia, ksenia_1, ksenia_2, ksenia_3, ksenia_4],
			className: 'my_woman__5 my_woman',
			birthdate: '1991-10-20',
			height: "1 m 65",
			status: t("women.single"),
			children: 1,
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (A1)`,
			profession: t("women.professions.economist")
		},
		{
			name: 'Diana',
			photos: [diana, diana_1, diana_2, diana_3, diana_4],
			className: 'my_woman__5 my_woman',
			birthdate: '1996-05-09',
			height: "1 m 50",
			status: t("women.single"),
			children: t("women.no"),
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (A1), ${t("women.languages.georgian")} (B1)`,
			profession: t("women.professions.economist")
		},
		{
			name: 'Alexandra',
			photos: [alexandra, alexandra_1, alexandra_2, alexandra_3, alexandra_4],
			className: 'my_woman__3 my_woman',
			birthdate: '1987-08-12',
			height: "1 m 70",
			status: t("women.divorced"),
			children: 2,
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (A1)`,
			profession: t("women.professions.medicalRepresentative")
		},
		{
			name: 'Svetlana',
			photos: [svetlana, svetlana_1, svetlana_2, svetlana_3, svetlana_4],
			className: 'my_woman__5 my_woman',
			birthdate: '1989-04-27',
			height: "1 m 63",
			status: t("women.single"),
			children: t("women.no"),
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (A1)`,
			profession: t("women.professions.psychologist")
		},
		{
			name: 'Ksenia',
			photos: [ksenia_second, ksenia_second_1, ksenia_second_2, ksenia_second_3, ksenia_second_4],
			className: 'my_woman__3 my_woman',
			birthdate: '1993-03-02',
			height: "1 m 67",
			status: t("women.single"),
			children: t("women.no"),
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (A1)`,
			profession: t("women.professions.medicalRepresentative")
		},
		{
			name: 'Elena',
			photos: [elena_second, elena_second_1, elena_second_2, elena_second_3, elena_second_4],
			className: 'my_woman__4 my_woman',
			birthdate: '1989-12-18',
			height: "1 m 72",
			status: t("women.single"),
			children: t("women.no"),
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (A2)`,
			profession: t("women.professions.salesManager")
		},
		{
			name: 'Svetlana',
			photos: [svetlana_second, svetlana_second_1, svetlana_second_2, svetlana_second_3, svetlana_second_4],
			className: 'my_woman__4 my_woman',
			birthdate: '1997-12-10',
			height: "1 m 75",
			status: t("women.single"),
			children: t("women.no"),
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (C1), ${t("women.languages.german")} (B1), ${t("women.languages.chinese")} (A2), ${t("women.languages.french")} (A2)`,
			profession: t("women.professions.hotelSupervisor")
		},
		{
			name: 'Irina',
			photos: [irina, irina_1, irina_2, irina_3],
			className: 'my_woman__3 my_woman',
			birthdate: '1993-07-28',
			height: "1 m 63",
			status: t("women.single"),
			children: t("women.no"),
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (B1)`,
			profession: t("women.professions.teacher")
		},
		{
			name: 'Polina',
			photos: [polina, polina_1, polina_2, polina_3],
			className: 'my_woman__5 my_woman',
			birthdate: '1993-10-07',
			height: "1 m 73",
			status: t("women.single"),
			children: t("women.no"),
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (A2), ${t("women.languages.italian")} (A2)`,
			profession: t("women.professions.makeUpArtist")
		},
		{
			name: 'Natalya',
			photos: [natalya_second_1, natalya_second_2, natalya_second_3, natalya_second_4],
			className: 'my_woman__5 my_woman',
			birthdate: '1993-08-06',
			height: "1 m 68",
			status: t("women.single"),
			children: t("women.no"),
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (A1)`,
			profession: t("women.professions.nataProf")
		},
		{
			name: 'Kristina',
			photos: [kristina_second, kristina_second_1, kristina_second_2, kristina_second_3],
			className: 'my_woman__4 my_woman',
			birthdate: '1980-11-12',
			height: "1 m 73",
			status: t("women.divorced"),
			children: 2,
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (A1), ${t("women.languages.french")} (A1)`,
			profession: t("women.professions.creativeDirector")
		},

	]

	const womansCol_2 = [
		{
			name: 'Anastasia',
			photos: [anastasia, anastasia_1, anastasia_2, anastasia_3, anastasia_4],
			className: 'my_woman__1 my_woman',
			birthdate: '1994-11-24',
			height: "1 m 60",
			status: t("women.divorced"),
			children: t("women.no"),
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (A1)`,
			profession: t("women.professions.teacher")

		},
		{
			name: 'Aigul',
			photos: [aigul, aigul_1, aigul_2, aigul_3, aigul_4],
			className: 'my_woman__3 my_woman',
			birthdate: '1985-09-05',
			height: "1 m 64",
			status: t("women.single"),
			children: 2,
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (A1), ${t("women.languages.kazakh")} (А2), ${t("women.languages.turkish")} (А1)`,
			profession: t("women.professions.marketer")

		},
		{
			name: 'Daria',
			photos: [daria, daria_1, daria_2, daria_3, daria_4],
			className: 'my_woman__1 my_woman',
			birthdate: '1985-12-20',
			height: "1 m 70",
			status: t("women.single"),
			children: t("women.no"),
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (C2), ${t("women.languages.german")} (C2), ${t("women.languages.french")} (C2)`,
			profession: t("women.professions.translator")

		},
		{
			name: 'Inna',
			photos: [inna, inna_1, inna_2, inna_3, inna_4],
			className: 'my_woman__4 my_woman',
			birthdate: '1969-04-12',
			height: "1 m 56",
			status: t("women.divorced"),
			children: 3,
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (A1)`,
			profession: t("women.professions.masseur")

		},
		{
			name: 'Anastasia',
			photos: [anastasia_second, anastasia_second_1, anastasia_second_2, anastasia_second_3, anastasia_second_4],
			className: 'my_woman__4 my_woman',
			birthdate: '1992-02-04',
			height: "1 m 65",
			status: t("women.single"),
			children: t("women.no"),
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (B1), ${t("women.languages.italian")} (A1)`,
			profession: t("women.professions.economist")
		},
		{
			name: 'Kate',
			photos: [kate, kate_1, kate_2, kate_3, kate_4],
			className: 'my_woman__3 my_woman',
			birthdate: '1999-12-22',
			height: "1 m 73",
			status: t("women.single"),
			children: t("women.no"),
			smoking: t("women.yes"),
			language: `${t("women.languages.english")} (B2)`,
			profession: t("women.professions.brandAmbassador")
		},
		{
			name: 'Rumia',
			photos: [rumia, rumia_1, rumia_2, rumia_3, rumia_4],
			className: 'my_woman__4 my_woman',
			birthdate: '1995-06-13',
			height: "1 m 65",
			status: t("women.single"),
			children: t("women.no"),
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (A1)`,
			profession: t("women.professions.makeUpArtist")
		},
		{
			name: 'Dayria',
			photos: [dayria, dayria_1, dayria_2, dayria_3, dayria_4],
			className: 'my_woman__5 my_woman',
			birthdate: '1986-12-13',
			height: "1 m 68",
			status: t("women.single"),
			children: t("women.no"),
			smoking: t("women.yes"),
			language: `${t("women.languages.english")} (A1)`,
			profession: `${t("women.professions.psychologist")}, ${t("women.professions.model")}`
		},
		{
			name: 'Kristina',
			photos: [kristina, kristina_1, kristina_2, kristina_3, kristina_4],
			className: 'my_woman__5 my_woman',
			birthdate: '1986-07-29',
			height: "1 m 65",
			status: t("women.single"),
			children: t("women.no"),
			smoking: t("women.yes"),
			language: `${t("women.languages.english")} (C1), ${t("women.languages.italian")} (B1)`,
			profession: t("women.professions.philologist")
		},
		{
			name: 'Valeria',
			photos: [valeria, valeria_1, valeria_2, valeria_3, valeria_4],
			className: 'my_woman__5 my_woman',
			birthdate: '1993-11-24',
			height: "1 m 65",
			status: t("women.single"),
			children: t("women.no"),
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (A1)`,
			profession: t("women.professions.photographer")
		},
		{
			name: 'Elena',
			photos: [elena_third, elena_third_1, elena_third_2, elena_third_3, elena_third_4],
			className: 'my_woman__4 my_woman',
			birthdate: '1975-01-22',
			height: "1 m 68",
			status: t("women.divorced"),
			children: 2,
			smoking: t("women.no"),
			language: `${t("women.languages.german")} (A1)`,
			profession: t("women.professions.teacher")
		},
		{
			name: 'Antonida',
			photos: [antonida, antonida_1, antonida_2, antonida_3, antonida_4],
			className: 'my_woman__3 my_woman',
			birthdate: '1979-03-27',
			height: "1 m 68",
			status: t("women.divorced"),
			children: 1,
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (A1)`,
			profession: t("women.professions.financier")
		},
		{
			name: 'Mila',
			photos: [mila, mila_1, mila_2, mila_3],
			className: 'my_woman__4 my_woman',
			birthdate: '2002-01-07',
			height: "1 m 63",
			status: t("women.single"),
			children: t("women.no"),
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (B1)`,
			profession: t("women.professions.mathematicTeacher")
		},
		{
			name: 'Olesya',
			photos: [olesya, olesya_1, olesya_2, olesya_3],
			className: 'my_woman__5 my_woman',
			birthdate: '1980-09-23',
			height: "1 m 70",
			status: t("women.single"),
			children: t("women.no"),
			smoking: t("women.yes"),
			language: `${t("women.languages.english")} (A1)`,
			profession: t("women.professions.coach")
		},
		{
			name: 'Elena',
			photos: [elena_fourth_1, elena_fourth_2, elena_fourth_3, elena_fourth_4, elena_fourth_5],
			className: 'my_woman__4 my_woman',
			birthdate: '1980-06-18',
			height: "1 m 68",
			status: t("women.single"),
			children: "1",
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (A2)`,
			profession: t("women.professions.salesManager")
		},
		{
			name: 'Larisa',
			photos: [larisa_1, larisa_2, larisa_3, larisa_4],
			className: 'my_woman__4 my_woman',
			birthdate: '1966-08-06',
			height: "1 m 75",
			status: t("women.divorced"),
			children: "2",
			smoking: t("women.no"),
			language: `${t("women.languages.english")} (A1), ${t("women.languages.french")} (A1)`,
			profession: t("women.professions.engineer")
		},
	]

	return (
		<div className={'womans_section'}>
			<div className="container womans">
				<h2 className="womans__name">{t('clients.title')}</h2>
				<div className="womans__columns">
					<div className="womans__columns__left-col">
						{womansCol_1.map(woman =>
							<Woman key={woman.name} {...woman} />
						)}
					</div>
					<div className="womans__columns__right-col">
						{womansCol_2.map(woman =>
							<Woman key={woman.name} {...woman} />
						)}
					</div>
				</div>
			</div>
			<Request background={'womans_request'} img={backWhite} />
		</div>
	);
};

export default Womans;